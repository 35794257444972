export default function useFavorites({ campsite: pCampsite }: { campsite: MaybeRefOrGetter<any> }) {
  const campsite = toRef(pCampsite);

  /********************
   * COMPOSITIONS      *
   ********************/
  const localePath = useLocalePath();
  const userStore = useUserStore();

  const campsiteSlug = computed(() => {
    return campsite.value?.slug;
  });

  const isFavorite = computed(() => {
    if (!userStore.loggedIn) {
      return false;
    }
    return userStore.favorites.includes(campsiteSlug.value);
  });

  /********************
   * FUNCTIONS         *
   ********************/
  async function toggleFavorite() {
    if (!userStore.loggedIn) {
      userStore.favoritePending = campsiteSlug.value;
      await navigateTo(
        localePath({
          name: RBN_LOGIN,
          query: {
            redirect: window.location.pathname,
          },
        }),
      );
    } else {
      dispatchFavoriteChange();
    }
  }

  function dispatchFavoriteChange() {
    if (isFavorite.value) {
      userStore.removeFavorite(campsiteSlug.value);
    } else {
      userStore.addFavorite(campsiteSlug.value);
    }
  }

  /********************
   * HOOKS             *
   ********************/
  onMounted(() => {
    if (userStore.favoritePending && userStore.loggedIn) {
      // This applies if login was previously triggered by clicking fav button
      const slug = userStore.favoritePending || campsiteSlug || campsite?.slug;
      setTimeout(() => userStore.addFavorite(slug), 300);
      userStore.favoritePending = false;
    }
  });

  return {
    isFavorite,
    toggleFavorite,
  };
}
