import type { LocationMetaInfo, Location, LocationTranslation } from '@/types';

export enum LocationContextType {
  campinginfo_city = 'campinginfo_city',
  campinginfo_country = 'campinginfo_country',
  campinginfo_federal_state = 'campinginfo_federal_state',
  campinginfo_region = 'campinginfo_region',
}
export enum LocationType {
  city = 'city',
  country = 'country',
  federal_state = 'federal_state',
  region = 'region',
}

export default function useECommerceContext() {
  function getContextForCP(campsite: any) {
    const context = [];

    context.push({
      schema: 'iglu:com.camparound/campinginfo_campsite/jsonschema/1-0-1',
      data: {
        booked_products: campsite.purchases || null,
        camping_id: campsite.camping_id,
        civ2_id: campsite.id,
        slug: campsite.slug,
      },
    });

    if (campsite.country && campsite.country.id && campsite.country.slugs?.en && campsite.country.name_en) {
      context.push(createLocationContext(LocationContextType.campinginfo_country, { id: campsite.country.id, slug: campsite.country.slugs?.en, name: campsite.country.name_en }));
    }

    if (campsite.federal_state && campsite.federal_state.id && campsite.federal_state.slugs?.en && campsite.federal_state.name_en) {
      context.push(createLocationContext(LocationContextType.campinginfo_federal_state, { id: campsite.federal_state.id, slug: campsite.federal_state.slugs?.en, name: campsite.federal_state.name_en }));
    }

    if (campsite.city_obj && campsite.city_obj.id && campsite.city_obj.slugs?.en && campsite.city_obj.name_en) {
      context.push(createLocationContext(LocationContextType.campinginfo_city, { id: campsite.city_obj.id, slug: campsite.city_obj.slugs?.en, name: campsite.city_obj.name_en }));
    }

    if (campsite.regions?.length) {
      campsite.regions.forEach((region: LocationTranslation) => {
        if (region.id && region.slugs?.en && region.name_en) {
          context.push(createLocationContext(LocationContextType.campinginfo_region, { id: region.id, slug: region.slugs?.en, name: region.name_en }));
        }
      });
    }

    return context;
  }

  function getLocationContextForSerp(location: Location): any[] {
    const context = [];

    const locationMetaInfo: LocationMetaInfo = { id: location.id, slug: location.slugs?.en, name: location.name_en };

    if (location.type === LocationType.city) {
      context.push(createLocationContext(LocationContextType.campinginfo_city, locationMetaInfo));
    }

    if (location.type === LocationType.country) {
      context.push(createLocationContext(LocationContextType.campinginfo_country, locationMetaInfo));
    }

    if (location.type === LocationType.federal_state) {
      context.push(createLocationContext(LocationContextType.campinginfo_federal_state, locationMetaInfo));
    }

    if (location.type === LocationType.region) {
      context.push(createLocationContext(LocationContextType.campinginfo_region, locationMetaInfo));
    }

    if (location.country) {
      context.push(createLocationContext(LocationContextType.campinginfo_country, { id: location.country.id, slug: location.country.slugs?.en, name: location.country.name_en }));
    }

    if (location.federal_state) {
      context.push(createLocationContext(LocationContextType.campinginfo_federal_state, { id: location.federal_state.id, slug: location.federal_state.slugs?.en, name: location.federal_state.name_en }));
    }

    return context;
  }

  function getContextForCPDP(campsite: any) {
    const context = [];

    context.push({
      schema: 'iglu:com.camparound/campinginfo_campsite/jsonschema/1-0-1',
      data: {
        booked_products: campsite.purchases || null,
        camping_id: campsite.camping_id,
        civ2_id: campsite.id,
        slug: campsite.slug,
      },
    });

    if (campsite.meta?.country && campsite.meta.country.id && campsite.meta.country.slugs?.en && campsite.meta.country.name_en) {
      context.push(createLocationContext(LocationContextType.campinginfo_country, { id: campsite.meta.country.id, slug: campsite.meta.country.slugs?.en, name: campsite.meta.country.name_en }));
    }

    if (campsite.meta?.federal_state && campsite.meta.federal_state.id && campsite.meta.federal_state.slugs?.en && campsite.meta.federal_state.name_en) {
      context.push(createLocationContext(LocationContextType.campinginfo_federal_state, { id: campsite.meta.federal_state.id, slug: campsite.meta.federal_state.slugs?.en, name: campsite.meta.federal_state.name_en }));
    }

    if (campsite.meta?.city_obj && campsite.meta.city_obj.id && campsite.meta.city_obj.slugs?.en && campsite.meta.city_obj.name_en) {
      context.push(createLocationContext(LocationContextType.campinginfo_city, { id: campsite.meta.city_obj.id, slug: campsite.meta.city_obj.slugs?.en, name: campsite.meta.city_obj.name_en }));
    }

    if (campsite.regions?.length) {
      campsite.regions.forEach((region: LocationTranslation) => {
        if (region.id && region.slugs?.en && region.name_en) {
          context.push(createLocationContext(LocationContextType.campinginfo_region, { id: region.id, slug: region.slugs?.en, name: region.name_en }));
        }
      });
    }

    return context;
  }

  function createLocationContext(type: LocationContextType, { id, slug, name }: LocationMetaInfo) {
    return {
      schema: `iglu:com.camparound/${type}/jsonschema/2-0-0`,
      data: {
        id,
        slug,
        name,
      },
    };
  };

  /********************
   * FUNCTIONS         *
   ********************/
  return {
    getContextForCP,
    getContextForCPDP,
    getLocationContextForSerp,
  };
}
